export default{
    message:'© Copyright 2024 Hefei Stack Top Information Technology Co., Ltd ',
    ICP:'Record Number: ',
    ICPNum:'Anhui ICP No. 20005502',
    license:'Network License: ',
    licenseText:'皖网文（2024）2138-025号',
    botMsgEmail:'Contact us: Email: ',
    emailAddress: 'kilonotes@top-stack.com',
    botMsgArgee:'privacy agreement'
}
