
<template>
  <div class="home_head animate__animated animate__slideInDown">
    <div class="headTitle">
      <img :src="isHome?require('../../assets/img/noteImage.png'):require('../../assets/img/topImage.png')" alt="">
      <span>{{msg.headTitle.filter(item=> item.key === $route.meta.headTitleKey)[0]['name']}}</span>
    </div>
    <div class="headLink">
      <router-link active-class="isChooseLink" :to="item.path" tag="span" v-for="item in msg.headLink" :key="item.path">{{item.name}}</router-link>
      <el-popover
          placement="bottom"
          title=""
          trigger="hover"
          popper-class="popoverStyle"
          @show="isHover = true"
          @hide="isHover = false"
      >
        <div class="showBox">
          <div  class="itemBox"
                v-for="(item,index) in msg.downloadData"
                @click="goDownLoad(item.type)"
                :key="item.type"
                @mouseover="hoverIndex = index"
                @mouseout="hoverIndex = -1">
            <img :src="item.img" alt="">
            <span :class="(hoverIndex > -1 && hoverIndex === index) ? 'activeSpan':''">{{item.name}}</span>
          </div>
        </div>
        <el-button type="primary" :style="isHover ? {background: 'rgba(46, 130, 255, 0.7)'}:''" round slot="reference">{{msg.headButton}}<i class="el-icon-arrow-down"></i></el-button>
      </el-popover>
    </div>
  </div>
</template>

<script>
import zhMessage from '../../assets/message/navBar/zh'
import enMessage from '../../assets/message/navBar/en'
import mixin from '../../mixin/toHerf'
export default{
  data(){
    return{
      hoverIndex:-1,
      isHover:false
    }
  },
  mixins:[mixin],
  computed:{
    langChina(){
      return navigator.language.startsWith('zh') ? true : false
    },
    msg(){
      return  navigator.language.startsWith('zh') ?  zhMessage : enMessage
    },
    isHome(){
      return this.$route.path === '/home' ? true:false
    }
  },
  created() {
    document.title = this.msg.headTitle.filter(item=> item.key === this.$route.meta.headTitleKey)[0]['name']
  },
  updated() {
    document.title = this.msg.headTitle.filter(item=> item.key === this.$route.meta.headTitleKey)[0]['name']
  },
  mounted(){
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      new this.$wow.WOW().init({
        boxClass: "wow", ///动画元件css类（默认为wow）
        animateClass: "animated", //动画css类（默认为animated）
        offset: 10, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      });
    });
  },
  methods:{

  }
}
</script>

<style scoped lang="scss">
.home_head{
  position: relative;
  z-index: 1000;
  width: 100%;
  height: 98px;
  background: rgba(255,255,255,0.85);
  backdrop-filter:blur(5px);
  border: 1px solid rgba(255,255,255,0.6);
  position: fixed;
  top: 0;
  left: 0;
  padding: 24px 113px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .headTitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    img{
      width: 50px;
      height: 50px;
      vertical-align: middle;
    }
    span{
      font-size: 32px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #000000;
      vertical-align: middle;
      margin-left: 14px;
    }
  }
  .headLink{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .isChooseLink{
      color: #2E82FF;
    }
    >span{
      display: inline-block;
      cursor: pointer;
      white-space: nowrap;
      font-size: 18px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      vertical-align: middle;
    }
    >span:hover{
      text-decoration:underline;
      text-underline-offset:8px;
    }
    >span:nth-child(1){
      margin-right: 72px;
    }
    >span:nth-child(2){
      margin-right: 72px;
    }
    >span:nth-child(3){
      text-decoration:none;
    }
    ::v-deep .el-button{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 150px;
      height: 40px;
      background: #2E82FF;
      border-radius: 22px;
      span{
        font-size: 18px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        .el-icon-arrow-down::before{
          margin-left: 6px;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
// el-popover中的元素样式和根节点平级
.showBox{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .activeBox{
    color: #2e82ff;
    background: #a4b7d2;
  }
  .itemBox{
    height: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    img{
      width: 24px;
      height: 24px;
    }
    .activeSpan{
      color: #2e82ff;
    }
    span{
      flex: 1;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-left: 18px;
    }
  }
}
</style>
<style lang="scss">
.el-popover.popoverStyle{
  padding: 20px 15px 25px 12px;
  box-sizing: border-box;
  height: 164px;
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.1);
  border-radius: 12px;
}
</style>
