
<template>
<div class="company animate__animated  animate__zoomIn">
  <div class="introBox">
      <div class="wow animate__animated animate__fadeInRight" :class="langChina?'msgBox':'msgBoxEn'">
          <div class="title">{{msg.titleTop}}</div>
        <p class="introP" v-for="item in msg.introList" :key="item.id">{{item.message}}</p>
          <div class="connect">{{msg.connectTitle}}</div>
          <p class="tel">{{ msg.tel }}</p>
          <p class="address">{{msg.address}}</p>
      </div>
  </div>
</div>
</template>

<script>
 import zhMessage from '../../assets/message/company/zh'
 import enMessage from '../../assets/message/company/en'
  export default {
   name:'company',
    computed:{
      langChina(){
        return  navigator.language.startsWith('zh') ? true : false
      },
      msg(){
        return  navigator.language.startsWith('zh') ?  zhMessage : enMessage
      }
    },
    mounted() {
      this.$nextTick(() => {
        // 在dom渲染完后,再执行动画
        new this.$wow.WOW().init({
          boxClass: "wow", ///动画元件css类（默认为wow）
          animateClass: "animated", //动画css类（默认为animated）
          offset: 10, //到元素距离触发动画（当默认为0）
          mobile: true, //在移动设备上触发动画（默认为true）
          live: true, //对异步加载的内容进行操作（默认为true）
        });
      });
    }
  }
</script>

<style lang="scss" scoped>
  .company{
    position: relative;
    z-index: -1;
    background: rgba(208,225,243,1);
    border: 1px solid rgba(255,255,255,0.61);
    margin-top: 98px;
    box-sizing: border-box;
    .introBox{
      height: 758px;
      background: url("../../assets/img/companyBackground.jpg") no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: flex-end;
      .msgBox{
        width: 770px;
        height: 760px;
        background: linear-gradient(180deg, rgba(19,110,226,0.8) 0%, rgba(46,130,255,0.76) 100%);
        border: 1px solid rgba(255,255,255,0.7);
        backdrop-filter: blur(4px);
        color: #FFFFFF;
        font-family: PingFangSC, PingFang SC;
        padding: 80px 98px 105px 72px;
        box-sizing: border-box;
        >div{
          font-size: 32px;
          font-weight: 600;
          margin-bottom: 15px;
        }
        .connect{
          padding-top: 30px;
          box-sizing: border-box;
        }
        p{
          margin: 0;
          padding: 0;
          font-size: 16px;
          font-weight: 400;
        }
        .introP{
          margin-bottom: 30px;
        }
        .tel{
          padding-top: 10px;
          margin-bottom:15px;
          box-sizing: border-box;
        }
      }
      .msgBoxEn{
        width: 770px;
        height: 760px;
        background: linear-gradient(180deg, rgba(19,110,226,0.8) 0%, rgba(46,130,255,0.76) 100%);
        border: 1px solid rgba(255,255,255,0.7);
        backdrop-filter: blur(4px);
        color: #FFFFFF;
        font-family: PingFangSC, PingFang SC;
        padding: 30px 30px 0 30px;
        box-sizing: border-box;
        >div{
          font-size: 32px;
          font-weight: 600;
          margin-bottom: 10px;
        }
        .connect{
          padding-top: 20px;
          box-sizing: border-box;
        }
        p{
          margin: 0;
          padding: 0;
          font-size: 16px;
          font-weight: 400;
          text-align: justify;
          word-spacing: 0.2em;
          letter-spacing: 0.5px;
        }
        .introP{
          margin-bottom: 20px;
        }
        .tel{
          padding-top: 10px;
          margin-bottom:10px;
          box-sizing: border-box;
        }
      }
    }
  }
</style>
