import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home/homeIndex.vue'
import Company from '../components/Company/companyIndex.vue'
import seeImage from '../components/component/seeImage.vue'

Vue.use(VueRouter)

const routes = [
    {
        path:'/',
        redirect:'/home'
    },
    {
        path:'/home',
        name:'home',
        meta:{
          headTitleKey:'home'
        },
        component:Home
    },
    {
        path:'/company',
        name:'company',
        meta:{
            headTitleKey:'company'
        },
        component:Company
    },
    {
        path:'/seeImage',
        name:'seeImage',
        meta:{
            headTitleKey:'seeImage'
        },
        component:seeImage
    }
]

const router = new VueRouter({
    mode:'hash',
    base:'/',
    routes
})

export default router
