<template>
  <div class="footer">
        <div class="message">
          <span>{{msg.message}}</span>
          <span style="margin-left: 15px" v-if="isZh">{{msg.ICP}}</span>
          <a v-if="isZh" href="https://beian.miit.gov.cn/" target="_blank">{{msg.ICPNum}}</a>
          <span style="margin-left: 20px" v-if="isZh">{{msg.license}}</span>
          <a v-if="isZh" href="./Anhui_Online_Literature_No.2138-025.pdf" target="_blank">{{msg.licenseText}}</a>
        </div>
        <div class="botMsg">
            <div>
              <span>{{msg.botMsgEmail}}</span>
              <a href="mailto:kilonotes@top-stack.com">{{ msg.emailAddress }}</a></div>
            <div>
              <a :href="toHerf" target="_blank">{{msg.botMsgArgee}}</a>
            </div>
        </div>
  </div>
</template>

<script>
  import zhMessage from "@/assets/message/Footer/zh";
  import enMessage from "@/assets/message/Footer/en";
  export default {
    computed:{
      isZh(){
        return  navigator.language.startsWith('zh')
      },
      toHerf(){
        return navigator.language.startsWith('zh') ? 'https://www.top-stack.com/page/ios_zh_privacy.html':'https://www.top-stack.com/page/ios_en_privacy.html'
      },
      msg(){
        return  navigator.language.startsWith('zh') ? zhMessage : enMessage
      },
    }
  }
</script>

<style scoped lang="scss">
.footer{
  height: 245px;
  background: #A6C6EA;
  font-size: 18px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #161647;
  padding-top: 64px;
  box-sizing: border-box;
  .message{
    text-align: center;
    a{
      color: #161647;
    }
  }
  .botMsg{
    padding: 0 402px 0 341px;
    box-sizing: border-box;
    margin-top: 19px;
    display: flex;
    justify-content: space-between;
    align-items: center;
      a{
        color: #161647;
      }
  }
}
</style>
