export default {
    videoBoxData:{
        titTop:'Kilonotes',
        titMidLeft:'Handwritten notes',
        titMidRight:'PDF reading',
        titMidList:[
            {
                name:'Record notes and ideas'
            },
            {
                name:''
            },
            {
                name:'PDF reading'
            },
            {
                name:''
            },
            {
                name:'Card learning'
            }
        ],
        downloadData:[
            {
                name:'IOS Download',
                img:require('../../img/ios_blue.png'),
                type:'ios'
            },
            {
                name:'Android Download',
                img:require('../../img/Android_blue.png'),
                type:'android'
            },
            {
                name:'Google play Download',
                img:require('../../img/GooglePlay.png'),
                type:'google'
            }
        ]
    },
    bigImageData:{
        title:'Kilonotes can meet your various needs',
        notice:'whether it is recording class notes, marking up PDFs, doing test exercises, ',
        noticeBot:'studying online classes, or organizing reading notes, Kilonotes will be your most reliable study partner',
        boxOne:{
            img:require('../../img/boxOneImg.jpg'),
            titleTop:'The best note-taking assistant to help you study ',
            colorTitle:'more efficiently'
        },
        boxTwo:{
            img:require('../../img/BoxTwoImg.jpg'),
            titleTop:'see what you want to see',
            titMid:'remember what you want to remember',
            colorTit:'Anytime, anywhere!',
        }
    },
    introBoxData:{
        titleHead:'A variety of special features to',
        titMid:'enhance your ',
        colorTit:'study, life and work efficiency',
        midIntroBoxDataOne:{
            messageTop:'Taking notes with a pencil is as natural as writing on paper.',
            image:require('../../img/midIntroImg.png')
        },
        midIntroBoxDataTwo:{
            messageTop:'Free labeling in PDF highlighting',
            image:require('../../img/midIntroImg2.png')
        },
        littleIntroBoxData:[
            {
                messageEnTop:'Customize your notebook to',
                messageEnBot:'show your personality',
                image:require('../../img/littleIntroImg1_en.png')
            },
            {
                messageEnTop:'Diversity of photo',
                messageEnBot:'editing tools',
                image:require('../../img/littleIntroImg2_en.png')
            },
            {
                messageEnTop:'Split-screen function allows you to',
                messageEnBot:'take notes while watching videos',
                image:require('../../img/littleIntroImg3_en.png')
            },
            {
                messageEnTop:'Quickly extract key points',
                image:require('../../img/littleIntroImg4_en.png')
            },
            {
                messageEnTop:'The recording feature makes',
                messageEnBot:'it easier to study and take notes',
                image:require('../../img/littleIntroImg5_en.png')
            },
            {
                messageEnTop:'Card-based learning',
                messageEnBot:'to build your knowledge base',
                image:require('../../img/littleIntroImg6_en.png')
            }
        ]
    }
}
