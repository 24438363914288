export const debounce = function (fun,time = 200){
    let timer
    return function(){
        clearTimeout(timer)
        let args = arguments
        timer=setTimeout(()=>{
            fun.apply(this,args)
        },time)
    }
}
