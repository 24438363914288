export default {
    videoBoxData:{
        titTop:'千本笔记',
        titMidLeft:'手写笔记',
        titMidRight:'PDF阅读',
        titMidList:[
            {
                name:'记录笔记和创意'
            },
            {
                name:''
            },
            {
                name:'PDF阅读'
            },
            {
                name:''
            },
            {
                name:'卡片学习'
            }
        ],
        downloadData:[
            {
                name:'ios下载',
                img:require('../../img/ios_blue.png'),
                type:'ios'
            },
            {
                name:'安卓下载',
                img:require('../../img/Android_blue.png'),
                type:'android'
            },
            {
                name:'Google play下载',
                img:require('../../img/GooglePlay.png'),
                type:'google'
            }
        ],
    },
    bigImageData:{
        title:'千本笔记能满足你的各种需求',
        notice:'无论是记录课堂笔记，标注PDF，做测试习题，学习网课， 还是整理读书笔记千本笔记都将是你最可靠的学习伙伴',
        boxOne:{
            img:require('../../img/boxOneImg.jpg'),
            titleTop:'最佳笔记助手',
            titMid:'帮你学习',
            colorTitle:'更高效'
        },
        boxTwo:{
            img:require('../../img/BoxTwoImg.jpg'),
            titleTop:'随时随地',
            titMid:'想看就看',
            colorTit:'想记就记'
        }
    },
    introBoxData:{
        titleHead:'多种特色功能',
        titMid:'提升你的',
        colorTit:'学习、工作、生活效率',
        midIntroBoxDataOne:{
                messageTop:'用PENCLE做笔记',
                messageBot:'就像在纸上写字一样',
                image:require('../../img/midIntroImg.png')
            },
        midIntroBoxDataTwo:{
            messageTop:'在PDF自由标注',
            messageBot:'突出显示',
            image:require('../../img/midIntroImg2.png')
        },
        littleIntroBoxData:[
            {
                messageTop:'自定义笔记本',
                messageBot: '彰显你的个性',
                image:require('../../img/littleIntroImg1.png')
            },
            {
                messageTop:'图片编辑',
                messageBot:'工具的多样性',
                image:require('../../img/littleIntroImg2.png')
            },
            {
                messageTop:'分屏功能 让你边看视频边做笔记',
                image:require('../../img/littleIntroImg3.png')
            },
            {
                messageTop:'快速摘取重点内容',
                image:require('../../img/littleIntroImg4.png')
            },
            {
                messageTop:'录音功能',
                messageBot:'让你的学习记录更方便',
                image:require('../../img/littleIntroImg5.png')
            },
            {
                messageTop:'卡片式学习',
                messageBot:'建立自己的知识库',
                image:require('../../img/littleIntroImg6.png')
            }
        ]
    }
}

