export default{
    headTitle:[
        {
            key:'home',
            name:'Kilonotes'
        },
        {
            key:'company',
            name:'T-Stack Information Technology'
        }
    ],
    headLink:[
        {
            name:'Product Description',
            path:'/home'
        },
        {
            name:'Company Profile',
            path:'/company'
        }
    ],
    headButton:'Download',
    downloadData:[
        {
            name:'ios',
            img:require('../../img/ios_black.png'),
            type:'ios'
        },
        {
            name:'Android',
            img:require('../../img/Android_black.png'),
            type:'android'
        },
        {
            name:'Google Play',
            img:require('../../img/GooglePlay.png'),
            type:'google'
        }
    ],
}
