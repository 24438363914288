import Vue from 'vue'
import router from './router/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './style/index.css'
import './utils/flexible'
import "video.js/dist/video-js.css";
import wow from 'wowjs'
import 'animate.css';
import App from './App.vue'
Vue.config.productionTip = false
Vue.prototype.$wow = wow
Vue.use(ElementUI);
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
