<template>
  <div id="app">
      <NavBar />
      <router-view />
      <Footer />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar/NavBar.vue";
import Footer from "@/components/Footer/Footer.vue";
export default {
  name: 'App',
  components: {
    NavBar,
    Footer
  },
  // 预加载背景图
  beforeCreate(){
    let count = 0;
    let imgs = [
      //用require的方式添加图片地址，直接添加图片地址的话，在build打包之后会查找不到图片，因为打包之后的图片名称会有一个加密的字符串
      require('./assets/img/background.jpg')
    ]
    for (let img of imgs) {
      let image = new Image();
      image.onload = () => {
        count++;
      };
      image.src = img;
    }
  }
}
</script>
<style lang="scss">
</style>
