<template>
  <div class="videoSquare">
    <div class="videoing">
      <video
          ref="player"
          preload="auto"
          :poster="require('../../assets/img/videoHome.jpg')"
          @canplay="getAllTime"
          @timeupdate="getNowTime"
          @click=" isVideoCtrlShow = !isVideoCtrlShow"
          autoplay
          muted>
        <source src="../../assets/video/introduce.mp4"/>
      </video>
    </div>
      <div  class="playBtn" v-if="isPlayContinue">
            <img class="btnPause" v-if="!isPlayContinue" @click="isPauseVideo" :src="playSvgSrc" alt="">
            <img class="btnPlay" v-if="isPlayContinue" @click="isPauseVideo" :src="playSvgSrc" alt="">
      </div>
    <el-button >
      <div></div>
    </el-button>
    <transition name="videoControl"
                enter-active-class="animate__animated animate__faster animate__fadeInUp"
                leave-active-class="animate__animated animate__faster animate__fadeOutDown">
      <div v-show="isVideoCtrlShow"
           class="videoControl "
      >
        <div class="ctrlBtn">
          <img @click="isPauseVideo" :src="isPlay ? require('../../assets/svgicon/play.svg') : require('../../assets/svgicon/pause.svg')" alt="">
        </div>
        <div class="progress" ref="progress">
          <c-progress class="c-progress"
                      :width="videoWidth"
                      :slider-width="sliderWidth"
                      :show-per-text="false"
                      :percent="progressTime"
                      @percentChange="onPercentChange" />
        </div>
        <div class="timer">
          <span class="nowTime">{{nowTime}}</span>
          <span>/</span>
          <span class="allTime">{{allTime}}</span>
        </div>
        <div class="speedCtrl">
          <div @click="showSpeedBox">倍速</div>
          <div class="speedBox" v-if="isShowSpeedBox">
            <span v-for="item in speedList" @click="speedClick(item.speed)" :key="item.id">{{item.speed}}</span>
          </div>
        </div>
        <div class="voiceCtrl">
          <div class="sliderBox"
               v-if="showSliderBox"
          >
            <el-slider
                v-model="voiceValue"
                vertical
                :step="1"
                :min="0"
                :max="100"
                @input="voiceChange"
            >
            </el-slider>
          </div>
          <img  @click="trunVoice"
               :src=" (voiceValue===0) ? require('../../assets/svgicon/voiceDown.svg') : require('../../assets/svgicon/voiceOn.svg')" alt="">
        </div>
        <div class="fullScreen">
          <i class="el-icon-full-screen" @click="fullScreen"></i>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import CProgress from './progess.vue'
import {debounce} from '../../utils/util'
export default  {
     data(){
       return{
         isVideoCtrlShow:false, //显示控件
         videotime:null, //播放器视频时间
         videoWidth:null, //播放器进度条宽度
         sliderWidth:null, //滑块大小
         isPlay:false,  // 视频是否正在播放
         recordScreen:1,  //定义一个变量，用于记录是否为全屏状态
         nowTime:null,   //当前播放时间
         allTime:null,   //视频总时间
         allSecond: 0,  //视频总秒数
         progressTime:0,
         isShowSpeedBox:false,
         playSvgSrc:'',
         isPlayContinue:false,
         isVoiceTurnOn:false,  // 当前声音是否打开
         voiceSrc:'',    // 当前是否静音
         voiceValue:0,  // 绑定音量
         nowVoice:50,  // 默认音量，记录当前音量
         showSliderBox:false,  //展示音量调节控制台
         enterSlider:false,
         speedList:[
           {
             id:0,
             speed:2
           },
           {
             id:1,
             speed:1.5
           },
           {
             id:2,
             speed:1.25
           },
           {
             id:3,
             speed:1
           },
           {
             id:4,
             speed:0.5
           }
         ]
       }
     },
    components:{
      CProgress
    },
    mounted(){
       window.addEventListener('resize',debounce(()=>{
         let screenWidth = screen.width
         this.videoWidth = (360 / 1440) * screenWidth
         this.sliderWidth = (15 / 1440) * screenWidth
       }),true)
           if(this.$refs.player.paused){
             this.isPlay = true
             this.playSvgSrc = require('../../assets/svgicon/pause_blue.svg')
           }else{
             this.isPlay = false
             this.playSvgSrc = require('../../assets/svgicon/play_blue.svg')
          }
    },
    methods: {
       // 播放按钮点击
      isPauseVideo:debounce(function(e){
        this.isPlayContinue = true
        if(this.$refs.player.paused){
          this.isPlay = true
          this.$refs.player.play()
          this.playSvgSrc = require('../../assets/svgicon/play_blue.svg')
          setTimeout(()=>{
            this.isPlayContinue = false
          },300)
        }else{
          this.isPlay = false
          this.$refs.player.pause()
          this.playSvgSrc = require('../../assets/svgicon/pause_blue.svg')
        }
      }),
      // 全屏按钮
      fullScreen(){
        this.recordScreen++;
        if(this.recordScreen++%2==0){
          //点击开启全屏
          if(this.$refs.player.requestFullScreen){
            this.$refs.player.requestFullScreen()
          }else if(this.$refs.player.webkitRequestFullScreen){
            this.$refs.player.webkitRequestFullScreen()//谷歌
          }else if(this.$refs.player.mozRequestFullScreen){
            this.$refs.player.mozRequestFullScreen()//火狐
          }else if(div01.msRequestFullscreen){
            this.$refs.player.msRequestFullscreen()//ie
          }
          }else{
          //关闭全屏
            if(document.cancelFullscreen){
              document.cancelFullscreen();
            }else if(document.webkitCancelFullScreen){
              document.webkitCancelFullScreen();
            }else if(document.mozCancelFullScreen){
              document.mozCancelFullScreen();
            }else if(document.msExitFullscreen){
              document.msExitFullscreen();
             }
            }
      },
      // 获取视频播放时间
      getAllTime(){
        let t = this.$refs.player.duration
        this.allSecond = Math.floor(t)
        let h = parseInt(t/3600);
        let m = parseInt(t%3600/60);
        let s = parseInt(t%60);
        h === 0 ? h = '00':''
        m === 0 ? m = '00':''
        this.allTime =  h === '00'? `${m}:${s}` :`${h}:${m}:${s}`
      },
      // 获取当前视频播放时间
      getNowTime(){
        let t = this.$refs.player.currentTime
        let h = parseInt(t/3600);
        let m = parseInt(t%3600/60);
        let s = parseInt(t%60);
        h === 0 ? h = '00':''
        m === 0 ? m = '00':''
        this.nowTime =  h === '00'? `${m}:${s<10?'0'+s:s}` :`${h}:${m}:${s}`
        this.progressTime = ((h * 3600 + m * 60 + s) / this.allSecond) * 100
        if(this.progressTime === 100) {
          this.isPlay = false
        }
      },
      // 动态计算进度条
      onPercentChange (per) {
        // 根据百分比计算当前时间显示和播放点
        this.$refs.player.currentTime = this.allSecond * (per / 100)
      },
      // 展示倍速弹框
      showSpeedBox() {
        this.isShowSpeedBox = !this.isShowSpeedBox
      },
      // 点击切换倍速播放
      speedClick(speed) {
        this.$refs.player.playbackRate = speed
        this.isShowSpeedBox = false
      },
      // 点击切换静音开关
      trunVoice() {
        this.showSliderBox = !this.showSliderBox
      },
      // 调整音量大小
      voiceChange:debounce(function(val) {
        if(val > 0){
          this.$refs.player.muted = false
        }
        this.nowVoice = val
        this.$refs.player.volume = val / 100
      },50)
    }
}

</script>

<style lang="scss" scoped>
.videoSquare{
  position: relative;
  height: 482px;
  .videoing{
    position: absolute;
    left: 0;
    top: 0;
    video{
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
  .playBtn{
    position: absolute;
    padding: 0;
    z-index:10;
    top: 50%;
    left: 50%;
    margin-left: -25px;
    margin-top: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: block;
      width: 50px;
      height: 50px;
    }
  }
  .videoControl{
    position: absolute;
    width: 100%;
    height: 30px;
    left: 0;
    bottom: 0;
    z-index:10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    background: #fff;
    .ctrlBtn{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      img{
        width: 22px;
        height: 20px;
        vertical-align: middle;
      }
    }
    .progress{
      width: 360px;
    }
    .timer{
      width: 100px;
      margin-left: 20px;
      font-size: 14px;
      padding: 0 5px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      span{
        flex: 3;
        letter-spacing: 1px;
      }
      span:nth-child(2){
        flex: 1;
        margin: 0 2px;
      }
    }
    .speedCtrl{
      width: 40px;
      font-size: 13px;
      margin-left: 6px;
      border: 1px solid;
      text-align: center;
      cursor: pointer;
      .speedBox{
        position: absolute;
        font-size: 13px;
        width: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        z-index:10;
        top: -115px;
        background: #fff;
        span{
          text-align: center;
          font-weight: 500;
          margin-bottom: 5px;
          cursor: pointer;
        }
      }

    }
    .voiceCtrl{
      width: 40px;
      display: flex;
      justify-content: center;
      .sliderBox{
        width: 30px;
        height: 100px;
        position: absolute;
        top: -100px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .el-slider{
        height: 70px;
      }
      img {
        display: block;
        width: 20px;
        height: 20px;
      }
    }
    .fullScreen{
      width: 30px;
      display: flex;
      justify-content: center;
      i{
        font-size: 16px;
      }
    }
  }
}

</style>
