<template>
    <div class="imageBox">
      <img src="../../assets/img/msg.jpeg" alt="">
    </div>
</template>
<script>
export default {
    name:'seeImage'
}
</script>
<style scoped lang="scss">
.imageBox{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}
</style>
