export default{
        titleTop:'公司简介',
        introList:[
            {
                message:'合肥栈顶信息科技有限公司是一家初创型技术研发公司，专注于移动互联网领域，致力于面向全球的移动应用研发，公司与合作伙伴共同开发运营的产品已在全球拥有超十亿用户。作为移动互联网领域的深耕者，栈顶用技术说话，让产品发光，打磨好每一款产品是我们不变的目标，栈顶立志成为全球开发者可信赖的技术伙伴。',
                id:1
            },
            {
                message:'在栈顶，你将获得和行业大佬切磋的机会。栈顶创始人拥有国内一线大厂多年资深技术研发背景，曾带领团队研发过多款工具、图片类应用，栈顶将助力每一名志同道合的伙伴在业务能力和工作经验上更上一层楼。',
                id:2
            },
            {
                message:'栈顶以开放的姿态期待每一个有自驱力、责任感、热爱技术、追求卓越的你，在简单可依赖的公司氛围中出谋划策共创未来。同时，栈顶也不会辜负你带来的每一份价值，让我们携手打造可以引领行业发展的科技公司。',
                id:3
            }
        ],
        connectTitle:'联系我们',
        tel:'电话：(0551) 6715 3689',
        address:'地址：安徽省合肥市高新区彩虹路222号创新国际B座17层'
}
