
<template>
  <div class="home">
    <div class="home_body">
      <div class="videoBox">
         <div class="wow animate__animated animate__slideInLeft" :class="langChina ? 'video_left':'video_left_en'">
            <div>
              <div>{{msg.videoBoxData.titTop}}</div>
              <div>
                <span>{{msg.videoBoxData.titMidLeft}}</span>
                <br v-if="!langChina" />
                <span class="andSpan">&</span>
                <span>{{msg.videoBoxData.titMidRight}}</span>
              </div>
            </div>
            <div>
              <span v-for="(item,index) in msg.videoBoxData.titMidList" :key="index">{{item.name}}</span>
            </div>
            <div>
                <el-button class="wow  animate__animated animate__slideInLeft" v-for="(item,index) in msg.videoBoxData.downloadData" @click="goDownLoad(item.type)" :key="index">
                  <img :src="item.img" alt="">
                  <span>{{item.name}}</span>
                </el-button>
            </div>
         </div>
         <div class="video_right wow animate__animated animate__slideInRight">
              <VideoBox />
         </div>
      </div>
      <div :class="langChina?'bigImageBox':'bigImageBox_en'">
          <div class="wow animate__animated animate__fadeInDown">
            <div>{{msg.bigImageData.title}}</div>
            <div class="stoneBlue"></div>
          </div>
          <div class="wow animate__animated animate__fadeIn">
            <span>{{msg.bigImageData.notice}}</span>
            <div v-if="msg.bigImageData.noticeBot">{{msg.bigImageData.noticeBot}}</div>
          </div>
          <div class="boxOne">
            <img class="wow animate__animated animate__slideInLeft" :src="msg.bigImageData.boxOne.img" alt="">
            <div class="wow animate__animated animate__slideInRight">
              <span>{{msg.bigImageData.boxOne.titleTop}}</span>
              <div>
                <span v-if="msg.bigImageData.boxOne.titMid">{{msg.bigImageData.boxOne.titMid}}</span>
                <span>{{msg.bigImageData.boxOne.colorTitle}}</span>
              </div>
            </div>
          </div>
          <div class="boxTwo">
            <div class="wow animate__animated animate__slideInLeft" v-if="langChina">
              <div>{{msg.bigImageData.boxTwo.titleTop}}</div>
              <div>
                <span>{{msg.bigImageData.boxTwo.titMid}}</span>
                <span>{{msg.bigImageData.boxTwo.colorTit}}</span>
              </div>
            </div>
            <div class="wow animate__animated animate__slideInLeft" v-if="!langChina">
              {{msg.bigImageData.boxTwo.titleTop + msg.bigImageData.boxTwo.titMid}} <span>{{msg.bigImageData.boxTwo.colorTit}}</span>
            </div>
            <img class="wow animate__animated animate__slideInRight" :src="msg.bigImageData.boxTwo.img" alt="">
          </div>
      </div>
      <div class="introBox">
         <div class="wow animate__animated animate__fadeInDown">
           <div>{{msg.introBoxData.titleHead}}</div>
           <div>
             <span>{{msg.introBoxData.titMid}}</span>
             <span>{{msg.introBoxData.colorTit}}</span>
           </div>
         </div>
         <div>
           <div class="wow animate__animated animate__slideInLeft">
             <div>{{msg.introBoxData.midIntroBoxDataOne.messageTop}}</div>
             <div v-if="msg.introBoxData.midIntroBoxDataOne.messageBot">{{msg.introBoxData.midIntroBoxDataOne.messageBot}}</div>
           </div>
           <img class="wow animate__slow animate__animated animate__jackInTheBox" :src="msg.introBoxData.midIntroBoxDataOne.image" alt="">
         </div>
         <div>
           <img class="wow animate__slow animate__animated animate__jackInTheBox" :src="msg.introBoxData.midIntroBoxDataTwo.image" alt="">
           <div class="wow animate__animated animate__slideInRight">
             <div>{{msg.introBoxData.midIntroBoxDataTwo.messageTop}}</div>
             <div>{{msg.introBoxData.midIntroBoxDataTwo.messageBot}}</div>
           </div>
         </div>
         <div>
            <div v-for="(item,index) in msg.introBoxData.littleIntroBoxData" :key="index">
              <img class="wow animate__animated" :class="index % 2 === 1 ? 'animate__zoomInRight':'animate__zoomInLeft'" :src="item.image" alt="">
              <div class="wow animate__animated animate__slideInUp" v-if="langChina">{{item.messageTop}}</div>
              <div class="wow animate__animated animate__slideInUp msgTopEn"  v-if="!langChina">
                 <span>{{item.messageEnTop}}</span>
                 <span v-if="item.messageEnBot">{{item.messageEnBot}}</span>
              </div>
              <div class="wow animate__animated animate__slideInUp" v-if="item.messageBot">{{item.messageBot}}</div>
            </div>
         </div>
      </div>
    </div>
  </div>
</template>

<script>
import zhMessage from '../../assets/message/home/zh'
import enMessage from '../../assets/message/home/en'
import mixin from '../../mixin/toHerf'
import VideoBox from '../component/videoBox.vue'
export default {
  name: 'homeIndex',
  data() {
    return {
      videoData:null
    }
  },
  components:{
    VideoBox
  },
  mounted(){
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      new this.$wow.WOW().init({
        boxClass: "wow", ///动画元件css类（默认为wow）
        animateClass: "animated", //动画css类（默认为animated）
        offset: 10, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      });
    });
  },
  mixins:[mixin],
  computed: {
    langChina() {
      return navigator.language.startsWith('zh') ? true : false
    },
    msg() {
      return navigator.language.startsWith('zh') ? zhMessage : enMessage
    }
  }
}

</script>

<style lang="scss" scoped>
.home{
  overflow: hidden;
  height: 5517px;
  background: url("../../assets/img/background.jpg") no-repeat;
  background-size: 100% 100%;
}
.home_body{
  padding-top: 88px;
  box-sizing: border-box;
  .videoBox{
    display: flex;
    justify-content: space-between;
    padding: 90px 130px 90px 122px;
    box-sizing: border-box;
    .video_left{
      width: 50%;
      padding: 63px 0 77px 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      >div:nth-child(1){
        font-weight: 500;
        font-size: 46px;
        color: #000;
        font-family: PingFangSC, PingFang SC;
        .andSpan{
          color: #2E82FF;
        }
      }
      >div:nth-child(2){
        margin-top: 21px;
        font-size: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #959FB0;
        span:not(:first-child){
          margin-left: 15px;
        }
        span:nth-child(even){
          display: inline-block;
          vertical-align: -2px;
          width: 2px;
          height: 18px;
          background: #959FB0;
          border: 1px solid #959FB0;
          box-sizing: border-box;
        }
      }
      >div:nth-child(3){
        margin-top: 48px;
        height: 158px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items:flex-start;
        ::v-deep .el-button{
          margin: 0;
          padding: 0;
          width: 190px;
          height: 42px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 24px;
          border: 1px solid #2E82FF;
          img{
            width: 24px;
            height: 24px;
            vertical-align: middle;
            margin-right: 12px;
          }
          span{
            font-size: 16px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: #2E82FF;
            vertical-align: middle;
          }
        }
      }
    }
    .video_right{
      position: relative;
      width: 700px;
      height: 482px;
      margin-top: 30px;
      box-shadow: 0px 2px 12px 0px rgba(17,17,23,0.1);
      border-radius: 16px;
      box-sizing: border-box;
      overflow: hidden;
    }
    // 兼容英文样式
    .video_left_en{
      width: 50%;
      padding: 23px 0 77px 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      >div:nth-child(1){
        font-size: 46px;
        font-family: "Arial Black";
        font-weight: 900;
        color: #000000;
        .andSpan{
          color: #2E82FF;
        }
      }
      >div:nth-child(2){
        width: 410px;
        margin-top: 21px;
        font-size: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #959FB0;
        span{
          vertical-align: middle;
        }
        span:not(:first-child){
          margin-left: 15px;
        }
        span:nth-child(even){
          display: inline-block;
          margin-top: 2px;
          width: 2px;
          height: 18px;
          border: 1px solid #959FB0;
          background: #959FB0;
          box-sizing: border-box;
        }
        span:last-child{
          margin-left: 0;
        }
      }
      >div:nth-child(3){
        margin-top: 48px;
        height: 158px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items:flex-start;
        ::v-deep .el-button{
          margin: 0;
          padding: 0;
          width: 235px;
          height: 42px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 24px;
          border: 1px solid #2E82FF;
          img{
            width: 24px;
            height: 24px;
            vertical-align: middle;
            margin-right: 12px;
          }
          span{
            font-size: 16px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: #2E82FF;
            vertical-align: middle;
          }
        }
      }
    }
  }
  .bigImageBox{
    margin-top: 155px;
    margin-bottom: 177px;
    >div:nth-child(1){
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      font-size: 36px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #2E82FF;
      letter-spacing: 2px;
      >div:nth-child(2){
        margin-top: 5px;
        margin-right: 40px;
        width: 36px;
        height: 5px;
        background: #2E82FF;
        border-radius: 3px;
      }
    }
    >div:nth-child(2){
      margin: 0 auto;
      margin-top: 17px;
      text-align: center;
      width: 549px;
      height: 44px;
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #959FB0;
    }
    .boxOne{
      margin-top: 52px;
      display: flex;
      justify-content: space-between;
      img{
        width: 985px;
        height: 424px;
      }
      >div{
        flex: 1;
        padding: 156px 0 156px 60px;
        box-sizing: border-box;
        font-size: 30px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #333333;
        letter-spacing: 1px;
        div:nth-child(2){
          margin-top: 30px;
          span:nth-child(2){
            color: #2E82FF;
          }
        }
      }
    }
    .boxTwo{
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      >div{
        flex: 1;
        padding: 272px 0 0px 120px;
        box-sizing: border-box;
        font-size: 30px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #333333;
        letter-spacing: 1px;
        div:nth-child(2){
          margin-top: 30px;
          span:nth-child(2){
            margin-left: 10px;
            color: #2E82FF;
          }
        }
      }
      img{
        width: 990px;
        height: 674px;
      }
    }
  }

  // 兼容英文样式
  .bigImageBox_en{
    margin-top: 65px;
    margin-bottom: 177px;
    >div:nth-child(1){
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      font-size: 36px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #2E82FF;
      letter-spacing: 2px;
      >div:nth-child(2){
        margin-top: 5px;
        margin-right: 100px;
        width: 36px;
        height: 5px;
        background: #2E82FF;
        border-radius: 3px;
      }
    }
    >div:nth-child(2){
      margin: 0 auto;
      margin-top: 17px;
      text-align: center;
      width: 760px;
      height: 44px;
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #959FB0;
    }
    .boxOne{
      margin-top: 52px;
      display: flex;
      justify-content: space-between;
      img{
        width: 985px;
        height: 424px;
      }
      >div{
        flex: 1;
        padding: 166px 0 0 60px;
        box-sizing: border-box;
        font-size: 30px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 42px;
        letter-spacing: 2px;
        div{
          display: inline-block;
          color: #2E82FF;
        }
      }
    }
    .boxTwo{
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      >div{
        flex: 1;
        padding: 235px 0 0 60px;
        font-size: 30px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #333333;
        letter-spacing: 2px;
        line-height: 42px;
        span{
              color: #2E82FF;
            }
      }
      img{
        width: 990px;
        height: 674px;
      }
    }
  }
  .introBox{
    display: flex;
    flex-direction: column;
    font-size: 36px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: #161647;
    letter-spacing: 2px;
    >div:nth-child(1){
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      span:last-child{
        color: #2E82FF;
      }
    }
    >div:nth-child(2){
      margin-top: 51px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 132px;
      padding-right: 48px;
      box-sizing: border-box;
      >div{
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 19px;
        box-sizing: border-box;
      }
      img{
        width: 802px;
        height: 523px;
      }
    }
    >div:nth-child(3){
      margin-top: 29px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 113px;
      box-sizing: border-box;
      >div{
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 67px;
        box-sizing: border-box;
      }
      img{
        width: 721px;
        height: 516px;
      }
    }
    >div:nth-child(4){
      margin-top: 75px;
      padding: 0 136px 0 150px;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      justify-content: space-between;
      font-size: 24px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #161647;
      >div{
        margin-top: 69px;
        width: 521px;
        display: flex;
        flex-direction: column;
        img{
          width: 500px;
          height: 358px;
        }
        div:not(.msgTopEn){
          display: flex;
          justify-content: center;
        }
        // 英文兼容样式
        .msgTopEn{
          text-align: center;
        }
      }
    }
  }
}

</style>
