export default{
    titleTop:'Company Profile',
    introList:[
        {
            message:'Hefei Stack Top Information Technology Co.Hefei Stack Top Information Technology Co., Ltd. is a startup technology research and development company, focusing on the field of mobile Internet, is committed to the development of mobile applications for the world, the company and its partners jointly developed and operated products have more than one billion users in the world. As a deep cultivator in the field of mobile Internet, StackTop uses technology to speak, let the product shine, and polish every product is our unchanging goal, StackTop is determined to become a reliable technology partner for developers around the world.',
            id:1
        },
        {
            message:'In StackTop, you will get the opportunity to consult with industry leaders. The founder of Stacktop has many years of senior technical research and development background in the domestic first-tier large factories, and has led the team to develop a variety of tools, picture applications, Stacktop will help every like-minded partner in the business ability and work experience to a higher level.',
            id:2
        },
        {
            message:'Stack Top is looking forward to every self-driven, responsible, technology-loving, and excellence-seeking you with an open attitude to create the future together in a simple and dependable company atmosphere. At the same time, Stack Top will not let you down for every value you bring, let\'s work together to build a technology company that can lead the development of the industry.',
            id:3
        }
    ],
    connectTitle:'Contact us',
    tel:'Tel：(0551) 6715 3689',
    address:'Address: 17/F, Building B, Innovation International, No. 222 Rainbow Road, Hi-tech Zone, Hefei City, Anhui Province, China.'
}
