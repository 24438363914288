export default{
    message:'© Copyright 2024 合肥栈顶信息科技有限公司  ',
    ICP:'备案号：',
    ICPNum:'皖ICP备20005502号',
    license:'网文许可证：',
    licenseText:'皖网文（2024）2138-025号',
    botMsgEmail:'联系我们：邮箱： ',
    emailAddress:'kilonotes@top-stack.com',
    botMsgArgee:'隐私协议'
}
