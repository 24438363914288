let mixin = {
    methods:{
        goDownLoad(type){
            switch (type){
                case 'google':
                    window.location.href = 'https://play.google.com/store/apps/details?id=com.topstack.kilonotes.pad'
                    break;
                case 'ios':
                    window.location = "itms-apps://itunes.apple.com/cn/app/id1514322479?mt=8";
                    break;
                case 'android':
                    window.location.href = 'https://shouji.baidu.com/detail/4016266?source=appbaidu'
                    break;
            }
        }
    }
}
export default mixin
